<style scoped>
.vti__dropdown-item {
    cursor: pointer;
    padding: 4px 15px;
    width: 100%;
}
</style>
<template>
  <div class="field-wrapper" v-bind:class="{ focus: isFocused || showLabel }">
    
 <label  v-if="isRequired" class="error-label" :class="inputLabelClass"> Required </label>
    <div class="labels" v-bind:class="{ error: showError }">
     <label v-if="showError" class="error-label1">{{ placeholder }}  <span  v-if="InvalidInput"> (  {{
      invalidInputMessage
    }}) </span> </label>
     
    </div>



    <div class="input-wrapper" v-bind:class="{ error: showError, required: alert}">

      <div style="background: blacK" >
      
      <span class="fieldset-bg" >
        <span
          class="fieldset-bg__alert"
          v-bind:class="{ 'fieldset-bg__alert--visible': showError }"
          v-if="isRequired"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="email-error"
            class=""
          >
            <path
              d="M4 8l1.5 1.5L4 11 2.5 9.5 4 8zm1-7v6H3V1h2z"
              fill="#FFF"
            ></path>
          </svg>


        </span>

      </span>

       

  

      <input
        v-if="type !== 'textarea' "
        v-bind:type="type"
        v-bind:placeholder="placeholder"
        @focus="onInputFocus"
        @blur="onInputBlur"
        @focusout="outInputFocus"
        v-on:input="onInputChange"
        v-bind:name="name"
        :value="modelValue"
       
          
        @input="$emit('update:modelValue', $event.target.value)"
      />


  </div>

<!--      
      <vue-tel-input v-if="type == 'tel'"   v-model="phone" mode="international" style="width: 100%"></vue-tel-input>
     
       -->
      
 
 
      <!-- <textarea
        v-if="type === 'textarea'"
        max="1000"
        min="10"
        v-bind:placeholder="placeholder"
        @focus="onInputFocus"
        @blur="onInputBlur"
        @focusout="outInputFocus"
        v-on:input="onInputChange"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      /> -->

    </div>



       
 
  </div>
</template>

<script>

 
  //  import { VueTelInput } from 'vue-tel-input';
  // import 'vue-tel-input/dist/vue-tel-input.css';
 
 


export default {
  name: "InputComponent",

  props: [
    "name",
    "type",
    "required",
    "modelValue",
    "fieldLabel",
    "placeholder",
    "invalidInputMessage",
  ],


  components: {
    // VueTelInput
   
  },



  data() {
    return {
      isFocused: false,
      showLabel: false,
      showError: false,
      InvalidInput: false,
      isRequired: false,
      phone: "",
      alert: false,
    };
  },
  computed: {
    inputLabelClass() {
      return {
        show: this.showLabel,
        focus: this.isFocused,
      };
    },
  },
  methods: {
    onInputFocus: function() {
      // `this` inside methods points to the Vue instance
      // if (evt.target.value === "" && this.required === "true") {
      //   this.showError = true;
      //   this.showLabel = false;
      //   this.isRequired= true;
      // }
      // else{
        
      //     this.isRequired= false;
      // }
      
      this.showError = true;
      this.isFocused = true;
    
    },

    outInputFocus: function(evt) {

     

      if(this.required === "true" && evt.target.value === ""){

        this.showError = true;
        this.isFocused = true;
        this.isRequired= true;
        this.alert=true;
      }
      else{
          this.showError = false;
          this.isFocused = false;
            this.isRequired= false;
      }
      

    

      if (evt.target.value != "") {
 
              if (this.name == "email") {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (re.test(evt.target.value) == false) {
                  this.showError = true;
                  this.isFocused = true;
                
                  this.InvalidInput = true;
                } else {
                  this.InvalidInput = false;
                  
                }
              }


                if (this.name == "website") {
        var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

        if (regexp.test(evt.target.value) == false) {
          this.showError = true;
          this.isFocused = true;
          this.showLabel = true;
          this.InvalidInput = true;
           
        } else {
          this.InvalidInput = false;
        }
      }


  



        if (this.name == "phone_number") {
           var ph= /^(00|\+)[1-9]{1}([0-9][\s]*){9,16}$/im;
 
     

        if (ph.test(evt.target.value) == false) {
          this.showError = true;
          this.isFocused = true;
          this.showLabel = true;
          this.InvalidInput = true;
           
        } else {
          this.InvalidInput = false;
        }
      }





         }

       else{
         this.InvalidInput = false;
       
       }

   




    




    },

    onInputChange: function(evt) {
      

        if (evt.target.value !== "") {
          this.showLabel = false;
          this.isRequired= false;
        } else {
          this.showLabel = true;
        }
      

        

      if (!evt.target.value) {
        if (this.name == "email") {
          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (re.test(evt.target.value) == false) {
            this.showError = true;
            this.isFocused = true;
            this.showLabel = true;
            this.InvalidInput = true;

            evt.preventDefault();
          }
        }


        if (this.name == "website") {
          var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

          if (regexp.test(evt.target.value) == false) {
            this.showError = true;
            this.isFocused = true;
            this.showLabel = true;
            this.InvalidInput = true;
            evt.preventDefault();
          } else {
            this.InvalidInput = false;
          }
        }


 

        
        if (this.name == "phone_number") {
           var ph=  /^(00|\+)[1-9]{1}([0-9][\s]*){9,16}$/im;
     

        if (ph.test(evt.target.value) == false) {
          this.showError = true;
          this.isFocused = true;
          this.showLabel = true;
          this.InvalidInput = true;
           
        } else {
          this.InvalidInput = false;
        }
      }



      } else {
        this.InvalidInput = false;
      }
 

       
    },
    onInputBlur: function(evt) {
      // `this` inside methods points to the Vue instance
      if (evt.target.value == "" && this.required !== undefined) {
        this.showError = true;
      }
      this.isFocused = false;
    },
  },
};
</script>
